body{
  font-family: 'Manrope', sans-serif;
}
.container{
  max-width: 1170px !important;
  padding: 0 !important;
  @media (max-width: 1100px){
    padding: 0 !important;
    &>div{
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
    }
  }
}
.container-big{
  max-width: 1440px !important;
  padding: 0 !important;
}
p.error{
  margin: 0;
  position: absolute;
  bottom: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 13px;
  color: red;
}
span.s4s{
  color: #ff9d00;
}

.btn.MuiButton-contained{
  border-radius: 5px;
  display: block;
  background-image: linear-gradient(to right, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
  width: 204px;
  height: 52px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 1.8px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
  box-shadow: none !important;

  @media (max-width: 550px){
    padding-left: 25px;
  }
  &.fixed{
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    @media (max-width: 900px){
      display: none !important;
    }
  }
}
h2{
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
  @media (max-width: 1100px){
    font-size: 35px;
  }
  @media (max-width: 900px){
    font-size: 26px;
  }
}

.title__sliderForm{
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 2.6px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
}


.company__box{
  padding-top: 35px;

  .complete-block {
    @media  (max-width: 550px){
      flex-wrap: wrap;
      justify-content: flex-end;

      button {
        padding-left: 16px;
      }
    }
  }

  .first-complete-block {
    @media  (max-width: 380px){
      gap: 10px;
      
      button {
        padding-left: 16px;
      }
    }
  }
}
 form .btn.MuiButton-contained{
   border-radius: 5px;
   display: block;
   background-image: linear-gradient(to right, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
   width: 204px;
   height: 52px;
   font-family: 'Montserrat', sans-serif;
   font-size: 18px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 40px;
   letter-spacing: 1.8px;
   text-align: center;
   color: #000;
   text-transform: uppercase;
   text-decoration: none;
   border: none;
   cursor: pointer;
   box-shadow: none !important;
   box-sizing: border-box;
}
.input-text{
  border-radius: 4.2px;
  border: solid 1.1px #d1d1d1;
  background-color: #fff;
  width: 100%;
  height: 50px;
  margin-bottom: 25px;
  padding-left: 15px;
  padding-right: 20px;
  font-family: 'Nunito Sans',sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  &:before, &:after{
    display: none;
  }
  input{
    font-family: 'Manrope',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    @media  (max-width: 900px){
      font-size: 14px;
    }
  }
}
.select-box{
  width: 100% !important;
  margin: 0 0 20px !important;

  label{
    font-family: 'Manrope',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;

    &.MuiInputLabel-shrink{
      position: absolute;
      left: 0;
      top: 0;
      max-width: calc(100% - 24px);
      padding: 0;
      display: block;
      font-family: 'Manrope',sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      transform-origin: top left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-transform: translate(14px, 16px) scale(1);
      -moz-transform: translate(14px, 16px) scale(1);
      -ms-transform: translate(14px, 16px) scale(1);
      transform: translate(14px, 16px) scale(1);
      -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      pointer-events: none;
      z-index: 1;

      @media  (max-width: 900px){
        font-size: 14px;
      }
    }

    &.MuiFormLabel-filled{
      display: none;
    }

    @media  (max-width: 900px){
      font-size: 14px;
    }
  }
  .select{
    height: 50px;
    .MuiSelect-select{
      border-radius: 4.2px;
      border: solid 1.1px #d1d1d1;
      background-color: #fff;
      width: 100%;
      height: 50px;
      padding: 14px 20px 14px 15px;
      font-family: 'Manrope',sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      box-sizing: border-box;
      &:before, &:after{
        display: none;
      }
      @media  (max-width: 900px){
        font-size: 14px;
      }
      input{
        font-family: 'Nunito Sans',sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        @media  (max-width: 900px){
          font-size: 14px;
        }
      }
    }
  }

  fieldset {
    border: none;
  }
}
.search-wrapper:after{
  content: 'Add Services';
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px 16px 11px 18px;
  border-radius: 18px;
  height: 36px;
  width: 150px;
  display: flex;
  font-family: Nunito Sans,sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left !important;
  justify-content: flex-start;
  text-transform: none !important;
  color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  cursor: pointer;

}

.textarea{
  padding: 19px 5px 5px 15px;
  border-radius: 4px;
  border: solid 1px #d1d1d1 !important;
  background-color: #fff;
  width: 100%;
  height: 125px;
  margin: 0 0 20px!important;

  fieldset {
    display: none;
  }
}
.servise-text{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  strong{
    display: block;
  }
}
.checkbox-box{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row !important;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .highlightOption, .multiSelectContainer li:hover {
    background: rgb(255, 198, 0);
    color: rgb(0, 0, 0);
  }
  &>div{
    width: 100%;
  }
    &>div >div{
      padding: 0;
    }
  .checkbox-servise, .select__multi-value{
    padding: 5px 16px 5px 18px;
    border-radius: 18px;
    background-color: #ffc600;
    height: 36px;
    max-width: 150px;
    text-align: left !important;
    justify-content: flex-start;
    text-transform: none !important;
    box-sizing: border-box;
    .select__multi-value__remove{
      padding: 0;
      &:hover{
        background: none;
      }
    }
    .select__multi-value__label{
      font-family: Nunito Sans,sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }
    svg{
      width: 16px;
      height: 16px;
      fill: #fff;
      cursor: pointer;
    }

  }
}

.checkbox{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;

  @media  (max-width: 550px) {
    width: 100% !important;
  }

  label{
    font-family: Manrope, sans-serif;
    font-size: 15.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    overflow:  visible;
    white-space: normal;

    @media  (max-width: 640px) {
      font-size: 13px;
      line-height: 1.5;
    }
    
    a{
      font-weight: 600;
      color: #fff;
    }
  }
}
.phone__box.PhoneInput{
  width: 100%;
  margin-bottom: 20px;
  display: inline-flex;
  select.PhoneInputCountrySelect{
    height: 50px;
    width: 100px;
  }
  .PhoneInputCountry{
    height: 50px;
    width: 100px;
    padding-left: 15px;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    box-sizing: border-box;
  }
  input{
    height: 50px;
    width: calc(100% - 100px);
    padding-left: 15px;
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
  }
  .PhoneInputCountrySelectArrow{
    margin-left: 40px;
  }
}
.contact{
  &__box{
    width: 100%;
  }
  &__banner{
    height: 425px;
    background: url("/img/contact_bg.jpg") no-repeat right top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1120px){
      height: auto;
      padding: 13vw 0;
      background: url("/img/contact_bg.jpg") no-repeat 65% top;
      background-size: cover;
    }
    @media (max-width: 900px){
      background: url("/img/contact_bg-mobile.jpg") no-repeat right top;
      background-size: cover;
      min-height: 180px;
      box-sizing: border-box;
      padding: 18vw 0;
    }

    @media (max-width: 480px){
      padding: 23vw 0;
    }

    h2{
      width: 100%;
      max-width: 1170px;
      margin-bottom: 0;
      font-size: 51.5px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.03px;
      text-align: left;
      color: #f4f5f7;

      @media (max-width: 1200px){
        padding-left: 25px;
      }

      @media (max-width: 900px){
        padding-left: 25px;
        font-size: 45px;
        line-height: 1.17;
        letter-spacing: -0.48px;
        text-align: left;
      }

      @media (max-width: 550px){
        font-size: 35px;
      }

      @media (max-width: 480px){
        font-size: 24px;
      }
    }
  }
  &__content{
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 90px 0;

    @media (max-width: 900px){
      padding: 0 0 30px;

      &>div{
        width: 100%;
      }
    }

    .MuiGrid-root {
      min-width: 542px !important;

      @media (max-width: 1200px){
        min-width: auto !important;
      }
    }
  }
  &__list{
    max-width: 500px;
    @media (max-width: 900px){
      max-width: 100%;
    }
  }
  &__help{

    &-box {
      max-height: 439px;
      max-width: 551px;
      padding: 70px 50px 60px 55px;
      border-radius: 10px;
      background-color: #f4f5f7;
      box-sizing: border-box;
      
      @media (max-width: 1220px){
        margin-left: 25px;
      }

      @media (max-width: 900px){
        min-height: 200px;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding: 25px;
      }
      h3 {
        max-width: 440px;
        margin: 0 0 20px;
        font-family: 'Montserrat',sans-serif;
        font-size: 45.5px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.91px;
        text-align: left;
        color: #000;

        @media (max-width: 900px){
          max-width: 500px;
          font-size: 35px;
          letter-spacing: -0.48px;
        }

        @media (max-width: 550px){
          font-size: 32px;
        }

        @media (max-width: 480px){
          font-size: 24px;
        }
      }
      p {
        margin-bottom: 35px;
        font-family: Manrope, sans-serif;
        font-size: 25.5px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: #e27484;
        text-transform: uppercase;

        @media (max-width: 900px){
          font-size: 21px;
          line-height: 1.09;
        }

        @media (max-width: 550px){
          font-size: 18px;
        }
  
        @media (max-width: 480px){
          font-size: 16px;
        }
      }
      li {
       padding: 0;
      }
    }
    &-avatar{
      height: 34px;
      width: 34px;
      margin: 0 20px 0 0;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 13px 22.5px 38px 0 rgba(10, 17, 33, 0.16);
      border-radius: 7px;
      @media (max-width: 900px){
        height: 40px;
        width: 40px;
        min-width: 40px !important;
        padding: 12px 8px;
        box-sizing: border-box;
        img{
          width: 100%;
        }
      }
      div{
        width: 100%;
        height: 100%;
        border-radius: 0;
        img{
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }
    &-text{
      &>span{
        font-family: 'Manrope',sans-serif;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: #000;

        @media (max-width: 900px){
          font-size: 18px;
        }

        @media (max-width: 550px){
          font-size: 16px;
        }
  
        @media (max-width: 480px){
          font-size: 14px;
        }
      }
      p, a{
        margin-bottom:0 ;
        font-family: 'Manrope',sans-serif;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        text-transform: none;
        text-decoration: none;

        @media (max-width: 900px){
          font-size: 18px;
        }

        @media (max-width: 550px){
          font-size: 16px;
        }
  
        @media (max-width: 480px){
          font-size: 14px;
        }
      }

    }
  }
}

#valid, #firstName, #lastName, #valid2{
  display: none;
}
.grecaptcha-badge {visibility: hidden;}