.footer__box{
  width: 100%;
  padding: 40px 0 45px;
  background-color: #e9ecef;
  @media (max-width: 900px){
    background: #fff;
    padding: 0;
  }
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    @media (max-width: 900px){
      justify-content: center;
    }
  }
  .left{
    width: 40%;
    @media  (max-width: 1170px){
      padding-left: 25px;
      box-sizing: border-box;
    }
    @media (max-width: 900px){
      width: 100%;
      order: 2;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px;
    }
    a{
      margin: 0 0 30px;
      display: inline-block;
      @media (max-width: 900px){
        margin-bottom: 0;
        svg{
          width: 20px;
        }
      }
    }
    p{
      margin: 0;
      font-family: Nunito Sans, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.84;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      @media (max-width: 900px){
        font-size: 11px;
      }
    }
  }
  .right{
    width: 60%;
    @media  (max-width: 1170px){
      padding-right: 25px;
      padding-left: 0;
      box-sizing: border-box;
    }
    @media (max-width: 900px){
      width: 100%;
      order: 1;
      background: #e9ecef;
      padding: 35px 25px;
    }
    h3{
      margin: 0 0 20px;
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.91;
      letter-spacing: normal;
      text-align: right;
      color: #000;
      text-transform: uppercase;
      @media (max-width: 900px){
        font-size: 15px;
        text-align: left;
        margin: 0 0 10px;
      }
    }
    form{
      &>div{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        @media (max-width: 900px){
          justify-content: flex-start;
          flex-wrap: wrap;
          column-gap: 15px;
        }

        @media (max-width: 480px){
          & > div {
            width: 100%;
          }
        }
      }
      .btn.MuiButton-contained{
        width: 134px;
        height: 50px;
        padding: 0;
        text-align: center;
        margin-left: 10px;
        @media (max-width: 900px){
          height: 36px;
          margin-left: 0;
          font-size: 14px;
        }

        @media (max-width: 480px){
          display: block;
        }
      }
      .input-text{
        width: 377px;
        font-family: Nunito Sans, sans-serif;
        input{
          font-family: Nunito Sans, sans-serif;
        }
        input::placeholder{
          text-align: right;
        }

        @media (max-width: 900px){
          width: 320px;
          height: 36px;
          margin-bottom: 10px;
        }

        @media (max-width: 640px){
          width: 271px;
        }

        @media (max-width: 480px){
          height: 45px;
        }
      }

      .error {
        @media (max-width: 900px){
          position: relative;
        }
      }
    }
  }
#form_news-ok{
  text-align: right;
  display: none;
}
}

.css-1l9qtcc {
  width: auto !important;
}
