.investor{
  &__box{
    padding: 70px 0;
    @media (max-width: 1190px){
      padding: 70px 25px;
      box-sizing: border-box;
    }

    h2{
      @media (max-width: 900px){
        margin: 30px auto;
        max-width: 205px;
        display: block;
      }
    }

    @media (max-width: 900px){
      padding: 0 0 30px;
    }

    @media (max-width: 760px){
      padding: 0;
    }
  }
  &__grid{
    margin-top: 65px;

    @media (max-width: 900px){
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0;
    }

    @media (max-width: 800px){
      column-gap: 5vw;
    }

    @media (max-width: 667px){
      column-gap: 3vw;
    }

    @media (max-width: 599px){
      column-gap: 0;
    }
  }
  &__item{
    min-height: 320px;
    width: 25%;
    max-width: 265px;
    padding: 35px 15px 0;
    border-radius: 12.7px;
    box-shadow: 7.5px 13px 45px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    @media (max-width: 1190px){
      width: 23%;
      min-height: 350px;
    }
    @media (max-width: 900px){
      width: 31%;
      max-width: 255px;
      min-height: 200px;
      margin: 0 auto 12px;
      padding: 25px 15px 0;
    }
    @media (max-width: 800px){
      width: 48%;
      max-width: 275px;
      min-height: 200px;
      margin: 0 0 12px;
    }
    @media (max-width: 600px){
      width: 100%;
      max-width: 275px;
      min-height: 200px;
      margin: 0 auto 12px;
    }
    
    img{
      margin: 0 auto;
      display: block;
      &.w97{
        width: 97px;
      }
      @media (max-width: 900px){
        width: 44px;
        &.w97{
          width: 59px;
        }
      }
    }
    h4{
      margin: 13px 0 15px;
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.34;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media (max-width: 900px){
        font-size: 16px;
        line-height: 1.52;
        letter-spacing: 0.78px;
        margin: 0 0 10px;

      }
    }
    p{
      margin: 0;
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media (max-width: 900px){
        font-size: 14px;
      }

    }
    &:first-of-type{
      margin-left: 0;
      background-color: #fbb0a7;
      @media (max-width: 900px){
        margin-left: auto;
      }
    }
    &:nth-of-type(2){
      background-color: #f798a4;

      @media (max-width: 900px){
        margin-right: auto;
      }

      h4{
        margin-top: 23px;
        @media (max-width: 900px) {
          margin-top: 10px;
        }
      }
    }
    &:nth-of-type(3){
      background-color: #e27484;
      
      @media (max-width: 900px){
        margin-left: auto;
      }

      h4{
        margin-top: 32px;
        @media (max-width: 900px) {
          margin-top: 10px;
        }
      }
    }
    &:last-of-type{
      margin-right: 0;
      background-color: #c76b65;
      h4{
        margin-top: 40px;
        @media (max-width: 900px) {
          margin-top: 10px;
        }
      }
      @media (max-width: 900px){
        margin-right: auto;
      }
    }
  }

  &__item_en {
    @media (max-width: 1050px) and (min-width: 901px) {
      min-height: 380px;
    }
  }
}