.slider{
  &__box{
    padding: 85px 0 35px;
    background: #e9ecef;
    @media (max-width: 900px){
      padding: 30px 0;
      h2{
        font-size: 26px;
        max-width: 250px;
        display: block;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  &__slider{
    width: calc(100% - 100px);
    max-width: 1170px;
    margin: 20px auto 0;
    .slick-list{
      height: 520px;

      @media (max-width: 900px){
        height: 300px;
      }

      @media (max-width: 350px) {
        margin: 0 -10px;
      }
    }
    .slick-prev{
      height: 23px;
      left: -70px;
      background: url("../../img/prev.png") no-repeat;
      &:before{display: none}
      @media (max-width: 1280px){
        left: -30px;
      }
    }
    .slick-next{
      height: 23px;
      right: -70px;
      background: url("../../img/next.png") no-repeat;
      &:before{display: none}
      @media (max-width: 1280px){
        right: -30px;
      }
    }

    .slick-slide{
      height: 516px;
      width: 446px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 900px) and (min-width: 481px){
        height: 300px;
        width: 290px !important;
      }

      @media (max-width: 480px){
        padding: 0;
        height: 300px;
      }

      @media (max-width: 350px) {
        & > div {
          padding: 0 10px;
        }
      }

      img{
        width: 266px;
        margin: 0;

        @media (max-width: 480px){
          padding: 0;
          width: 220px;
        }
      }

      &.slick-center {
        padding: 0;

        img {
          width: 446px;
          display: block;
          margin: 25px 0;
          transition: .3s;


          @media (max-width: 900px) {
            width:270px;
          }
        }
      }
    }
  }
}
