.command{
  &__box{
    max-width: 1170px;
    margin: 0 auto;
    padding: 80px 0 65px;
    @media (max-width: 900px) {
      padding: 35px 0;
    }
  }
  &__grid{
    margin-top: 50px;
  }
  &__item{
    margin: 0 13px 15px;
    max-width: 212px;
    @media (max-width: 900px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 305px;
      margin: 0 15px 15px;
      img{
        width: 109px;
      }
    }
    .text{
      margin-top: 20px;
      @media (max-width: 900px) {
        margin: 0 0 0 20px;
      }
      h4{
        font-family: Manrope, sans-serif;
        font-size: 21.5px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e27484;
        margin-bottom: 15px;
        margin-top: 0;
        @media (max-width: 900px) {
          font-size: 16px;
          text-align: left;
          margin-bottom: 5px;
        }
      }
      p{
        margin: 0;
        font-family: Manrope, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        white-space: pre-line;
        color: #000;

        strong{
          font-weight: 600;
          display: block;
        }
        @media (max-width: 900px) {
          font-size: 16px;
          text-align: left;
        }

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }
}