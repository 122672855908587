.about{
  &__box{
    padding: 75px 0 100px;
    background-color: rgba(233,236,239, 0.5);

    @media (max-width: 1200px) and (min-width: 1100px){
      padding: 75px 25px 100px;
    }

    @media (max-width: 900px){
      padding: 30px 0;
    }
    .container{
      margin: 0 auto;
    }
    h2{
      max-width: 1025px;
      margin: 0 auto 60px;
      display: block;
      font-family: Manrope, sans-serif;
      font-size: 25.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      text-transform: uppercase;

      @media (max-width: 1050px){
        padding: 0 30px;
      }

      @media (max-width: 900px){
        font-size: 16px;
        padding: 0 25px;
        margin: 0 auto 35px;
      }
    }
  }
  &__grid{
    @media (max-width: 900px){
      justify-content: center !important;
    }
  }
  &__item{
    width: 33%;
    max-width: 345px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 900px){
      width: 100%;
      margin-bottom: 20px;
      &:first-of-type{
        img{
          width: 41px;
        }
      }
    }
    &-img{
      height: 97px;
      width: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12.7px;
      box-shadow: 15.5px 26.8px 45px 0 rgba(0, 0, 0, 0.04);
      background-color: #fff;
      @media (max-width: 900px){
        width: 64px;
        height: 64px;
        img{
          width: 35px;
        }
      }
    }
    h4{
      margin: 25px 0 20px;
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #e27484;
      text-transform: uppercase;
      @media (max-width: 900px){
        margin: 15px 0 10px;
        font-size: 15.5px;
      }
    }
    p{
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      margin: 0;
      @media (max-width: 1100px) {
        padding: 0 15px;
      }
      @media (max-width: 900px){
        margin: 0;
        font-size: 13.5px;
      }
    }
    &:first-of-type{
      margin-left: 0;
    }
    &:last-of-type{
      margin-right: 0;
    }
  }
}