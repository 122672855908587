.why{
  &__box{
    width: 100%;
    margin: 45px auto 90px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    flex-wrap: nowrap;

    @media (max-width: 1240px){
      img{
        display: none;
      }
    }
    @media (max-width: 1100px){
      padding-left: 0 !important;
      padding-right: 0 !important;

    }
    @media (max-width: 900px){
      margin: 0 auto;
      width: 100%;
      flex-wrap: wrap;

      h2{
        text-align: center;
      }
      .btn{
        display: block;
        bottom: 0;
        width: 300px;
        right: calc((100% - 310px)/2);
        text-align: center;
        padding: 10px;
      }
    }
  }
  &__img{
    display: none;
    text-indent: -9999px;
    background: url("../../img/beneficios-bg.jpg") no-repeat top center;
    
    @media (max-width: 1240px){
      display: block;
      height: 550px;
      width: 50%;
      background: url("../../img/beneficios-bg.jpg") no-repeat top center;
      background-size: cover;

    }
    @media (max-width: 1000px){
      height: 600px;
    }
    @media (max-width: 900px){
      height: 650px;
    }
    @media (max-width: 760px){
      width: 100%;
      height: 345px;
    }
  }
  &__text{
    width: 40%;
    max-width: 520px;
    box-sizing: border-box;
    margin-left: calc((1440px - 1170px)/2);
    @media (max-width: 1350px) {
      margin-left: 90px;
    }
    h2{
      text-align: left;
      color: #000;
      margin: 0 0 35px;
      @media (max-width: 900px){
        text-align: center;
      }
    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        background: url("../../img/arrow-right.png")no-repeat left 10px;
        margin-bottom: 15px;
        padding-left: 46px;
        font-family: Manrope, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        strong{
          display: block;
          font-size: 20px;
        }
        @media (max-width: 900px) {
          font-size: 14px;
          strong{
            font-size: 14px;
          }
        }
      }
    }
    @media (max-width: 1200px){
      width: 40%;
      margin-left: 25px;
        }
    @media (max-width: 760px){
      width: 100%;
      max-width: 96%;
      padding: 30px 25px 35px 0;

    }
  }
}