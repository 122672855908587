.banner{
  &__box{
    position: relative;
    width: 100%;
    @media (max-width: 1190px){
      padding: 0 25px;
      box-sizing: border-box;
    }

    @media  (max-width: 1890px){
      min-height: 668px;
      width: 100%;
      margin: 0;
      background: url("../../img/banner.png") no-repeat right center;
      background-size: cover;
      img{
        display: none;
      }
    }
    @media (max-width: 1100px){
      background: url("../../img/banner.png") no-repeat 70% center;
    }
    @media  (max-width: 900px){
      background:  url("../../img/banner.png") no-repeat 72% top;
      background-size: cover;
      padding-bottom: 40px;
    }
    @media  (max-width: 567px){
      background: url("../../img/banner-mobile.jpg") no-repeat right top;
      background-size: cover;
      min-height: 430px;
    }
    img{
      min-height: 620px;
      width: 100%;
    }
  }
  &__text{
    position: absolute;
    top: 15%;
    left: calc((100vw - 1170px)/2);
    max-width: 1170px;
    width: 100%;

    @media (min-width: 900px){
      .btn.mobile{
        display: none !important;
      }
    }
    @media  (max-width: 1890px){
      position: initial;
      margin:0 auto;
      padding-top: 100px;
    }
    @media  (max-width: 1150px){
      width: auto;
      margin: 0 0 0 10px;
    }
    @media  (max-width: 1100px){
      margin: 0 0 0 25px;
      padding-top: 50px;
    }
    @media (max-width: 900px){
      margin: 0 ;
      padding-top: 200px;

      .btn.mobile{
        width: calc(100% - 50px);
        height: 37px;
        position: fixed;
        bottom: 70px;
        left: 25px;
        box-shadow: 0 0 12.6px 5.4px rgba(10, 17, 33, 0.17) !important;
        display: block;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 32px;
        letter-spacing: 1.4px;
        color: #000;
        z-index: 100;
        text-align: center;
        padding: 0;
      }
    }

    @media  (max-width: 667px){
      padding-top: 70px;
    }

    h2{
      max-width: 765px;
      margin: 0;
      font-size: 51.5px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.03px;
      color: #fff;
      text-align: left;

      @media  (max-width: 1100px){
        max-width: 650px;
      }

      @media (max-width: 900px){
        font-size: 24px;
        line-height: 1.17;
        letter-spacing: -0.48px;
        max-width: 50%;
        min-width: 200px;
      }
    }
    p{
      max-width: 580px;
      margin: 40px 0 40px;
      font-family: 'Manrope', sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #fff;
      .fs27{
        font-size: 27.5px;
        margin-top: 10px;
        display: block;
      }
      @media  (max-width: 900px){
        margin: 20px 0 20px;
        font-size: 14.5px;
        line-height: 1.41;
        letter-spacing: normal;
        width: 50%;
        min-width: 270px;
        .fs27{
          font-size: 16px;
        }
      }
    }

    .bottomBox {
      display: flex;
      align-items: center;
      gap: 45px;

      @media  (max-width: 900px){
        flex-wrap: wrap;
        gap: 10px;

        .btn.nomobile {
          max-width: 400px;
          width: 100%;
          height: 37px;
          padding: 0;
          display: block;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.4px;
          text-align: center;
          color: #000;
          box-shadow: 0 0 12.6px 5.4px rgba(10, 17, 33, 0.17) !important;
          z-index: 100;
        }
      }

      p {
        margin: 0;
        font-family: 'Manrope', sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #fff;

        @media  (max-width: 900px){
          margin: 0;
          font-size: 14.5px;
          line-height: 1.41;
          letter-spacing: normal;
          width: 50%;
          min-width: 270px;
        }
      }
    }
  }
}