.steps{
  &__box{
    width: 100%;
    padding: 100px 0 80px;
    background: url("../../img/steps-bg.jpg") no-repeat;
    background-size: cover;

    @media (max-width: 900px) {
      background: url("../../img/bg-steps-mobile.jpg") no-repeat;
      background-size: cover;
      padding: 45px 0 70px;
    }
    .container-big{
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;

      @media (max-width: 1170px) {
        justify-content: center;
      }

      @media (max-width: 900px) {
        flex-wrap: wrap;
      }
    }
  }
  &__text{
    width: 360px;
    margin-left: calc((1440px - 1170px)/2 + 105px);
    margin-right: 90px;
    @media (max-width: 1240px) {
      margin-right: 50px;
    }
    @media (max-width: 1170px) {
      margin-left: 0;
    }
    @media (max-width: 900px) {
      width: 100%;
      margin-right: 0;
      .btn{
        display: none;
      }
    }
    h2{
      margin-bottom: 40px;
      font-size: 52px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fafafa;
      text-transform: uppercase;

      strong{
        font-weight: 600;
      }
      @media (max-width: 900px) {
        font-size: 28px;
        margin:0 auto 35px;
        text-align: center;
        display: block;
        max-width: 200px;
      }
    }
  }

  &__box-list{
    width: 50%;
    max-width: 525px;

    @media (max-width: 900px){
      width: 100%;
      max-width: 350px;
    }

    @media (max-width: 420px) {
      max-width: unset;
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;

      @media (max-width: 420px) {
        padding: 0 26px 0 18px;
      }

      li{
        height: 94px;
        margin-bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 900px){
          height: 51px;
          align-items: center;
        }

        @media (max-width: 480px) {
          svg {
            max-width: auto !important;
          }
        }

        &:first-of-type{
          svg{
            height: 38px;
            min-width: 40px;

            @media (max-width: 480px) {
              height: 20px;
              width: 22px;
              min-width: 22px !important;
            }
          }
        }
        &:nth-of-type(2){
          svg{
            min-height: 43px;
            min-width: 43px;

            @media (max-width: 480px) {
              height: 23px;
              width: 23px;
              min-width: 23px !important;
            }
          }
        }
        &:nth-of-type(3){
          svg{
            height: 39px;
            min-width: 49px;

            @media (max-width: 480px) {
              height: 22px;
              width: 28px;
              min-width: 28px !important;
            }
          }
        }
        &:last-of-type{
          svg{
            height: 41px;
            min-width: 41px;

            @media (max-width: 480px) {
              height: 22px;
              width: 22px;
            }
          }
        }

        .number{
          height: 94px;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: Montserrat, sans-serif;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: rgba(250, 250, 250, 0.95);
          border-right: 3px solid #e9ecef;
          position: relative;
          &:before{
            content: '';
            height: 24px;
            width: 24px;
            display: block;
            position: absolute;
            top: calc(50% - 12px);
            right: -12px;
            background: #000;
            z-index: 2;
          }
          &:after{
            content: '';
            height: 14px;
            width: 14px;
            position: absolute;
            top: calc(50% - 7px);
            right: -9px;
            border-radius: 50%;
            background-color: #e8e7e5;
            z-index: 3;
          }
          @media (max-width: 900px){
            width: 30px;
            height: 46px;
            border: none;
            font-size: 16px;
            justify-content: flex-end;
            &:before, &:after{
              display: none;
            }
          }
        }

        .text{
          height: 84px;
          width: 411px;
          margin-left: 20px;
          padding: 0 25px 0 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: Manrope, sans-serif;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          white-space: pre-line;
          color: #000;
          border-radius: 7.4px;
          background-color: #fff;
          box-sizing: border-box;

          svg{
            fill: #f797a3;
            @media (max-width: 900px){
              min-height: auto;
              min-width: 25px;
              max-width: 25px;
            }
          }

          @media (max-width: 930px){
            font-size: 21px;
          }

          @media (max-width: 900px){
            font-size: 14px;
            height: 46px;
            width: 300px;
            padding: 0 10px 0 15px;
          }

          @media (max-width: 420px) {
            width: 76.25vw;
          }
        }

        .text_en {
          font-size: 18px;

          @media (max-width: 1000px){
            font-size: 16px;
          }

          @media (max-width: 920px){
            font-size: 15px;
          }

          @media (max-width: 900px){
            font-size: 14px;
          }

          @media (max-width: 380px) {
            font-size: 12px;
          }

          @media (max-width: 332px) {
            font-size: 11px;
          }
        }

        &:first-of-type{
          .number{
            height: 90px;
            margin-top: 4px;

            @media (max-width: 900px){
              height: 46px;
            }
          }
        }
        &:last-of-type{
          .number{
            height: 80px;
            margin-bottom: 4px;
            @media (max-width: 900px){
              height: 46px;
            }
          }
        }
        &:hover{
          .number{
            &:after{
              background-color: #f996a2;
            }
          }

          .text{
            cursor: pointer;
            background-color: #f996a2;
            font-weight: bold;
            color: #fff;
            svg{
              fill: #fff;
            }
          }
        }
      }
    }
  }
}