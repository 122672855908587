.faq{
  &__box{
    width: 100%;
    padding: 95px 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    @media (max-width: 1190px){
      padding: 95px 25px 80px;
      box-sizing: border-box;
    }
    @media (max-width: 900px){
      padding: 40px 0;
      .btn{
        display: none;
      }
    }
  }
  &__left{
    max-width: 290px;
    margin: 0 auto;
    @media (max-width: 1100px){
      max-width: 250px;
    }
    h2{
      margin-bottom: 40px;
      text-align: left;

      @media (max-width: 900px){
        max-width: 170px;
        margin: 0 auto 30px;
        display: block;
      }
    }

    .title_en {
      @media (max-width: 667px){
        max-width: unset;
      }
    }
  }
  &__accordion{
    max-width: 742px;
    @media (max-width: 1100px){
      width: 70%;
    }
    @media (max-width: 900px){
      font-size: 14px;
      .MuiAccordionSummary-content{margin: 0 !important;}

    }
    @media (max-width: 667px){
      width: 100%;
    }
    strong{
      font-family: Manrope, sans-serif;
      font-size: 22px;
      @media (max-width: 900px){
        font-size: 14px;
      }
    }
    .MuiAccordionSummary-expandIconWrapper{
      height: 30px;
      width: 30px;
      background: url("../../img/more.png") no-repeat right center;
      @media (max-width: 900px){
        background-size: 22px;
      }
      &.Mui-expanded{
        background: url("../../img/minus.png") no-repeat right center;
        @media (max-width: 900px){
          background-size: 22px;
        }
      }
    }
    .MuiAccordion-region {
      div{
        p{
          font-family: Manrope, sans-serif;
          font-size: 18px;
          max-width: 89%;
          line-height: 1.42;
          white-space: pre-line;

          @media (max-width: 900px){
            font-size: 14px;
          }
        }
      }
    }
  }
}