.header{
  @media (max-width: 1190px){
    padding: 0 25px;
  }

  & div:first-child {
    @media (max-width: 350px){
      flex-wrap: nowrap;
    }
  }

  &__menu{
    @media (max-width: 1000px){
      max-width: 83% !important;
      flex-basis: auto !important;
    }

    @media (max-width: 900px){
      justify-content: flex-end;
      align-items: center;
    }

    &-icon{
      display: none;

      @media (max-width: 900px) {
        display: block;

        svg {
          width: 28px;
          height: 20px;
          fill: #000;

          path {
            width: 100%;
          }
        }
      }
    }
  }
}

.menu__box{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 900px) {
    padding-bottom: 0 !important;
  }

  .menu__link{
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 6.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    margin-left: 40px;

    @media (max-width: 1170px){
      font-size: 14px;
      margin-left: 20px;
    }

    @media (max-width: 900px){
      width: 100%;
      margin-left: 0;
      padding-left: 50px !important;
      padding-right: 12px !important;
      padding-bottom: 15px;
      display: block;
      font-size: 14px;
      line-height: 1.1;
  
      &:last-child{
        padding-bottom: 0;
      }
    }
  }
}

.menu_popper {
  padding: 8px 0;
  flex-direction: column !important;
  background: #fff;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  overflow: hidden;
  z-index: 1000;
}

.logo{
  height: 70px;
  width: 251px;
  @media (max-width: 1100px){
    height: auto;
    width: 140px;
  }
  @media (max-width: 900px){
    height: 43px;
    width: 153px;
  }
}

.selectLanguage {
  margin-left: 40px;

  @media (max-width: 1190px){
    margin-left: 20px;
  }

  .MuiSelect-select {
    min-width: 30px;
    height: 30px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 6.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;

    @media (max-width: 1170px){
      font-size: 14px;
    }

    @media (max-width: 350px){
      padding: 0;
    }
  }

  fieldset {
    display: none;
  }
}

.selectLanguageMenu {
  font-family: Montserrat, sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  background: transparent !important;

  @media (max-width: 900px){
    font-size: 14px !important;
  }
}

.languageAccordion {
  width: 100%;
  box-shadow: none !important;

  .MuiAccordionSummary-root {
    min-height: auto;
    padding: 0 12px 10px 20px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    background: transparent;
    background: none;
    border: none;


    &.Mui-expanded {
      min-height: auto;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiCollapse-root .MuiAccordionDetails-root {
    padding: 0 0 5px;
    display: flex;
    flex-direction: column;

    .menuItems {
      padding: 8px 12px 8px 20px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #444444 !important;
      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: rgba(0,0,0,.03);
      }
    }
  }
}
