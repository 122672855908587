.form{
  &__box{
    background: url("../../img/form-bg.jpg") no-repeat;
    background-size: cover;
    padding: 75px 0 60px;

    @media  (max-width: 900px){
      padding: 30px 0 70px;
      background: url("../../img/bg-form-mobile.jpg") no-repeat;
      background-size: cover;
    }

    @media  (max-width: 550px) {
      padding: 33px 0 18px;
    }

    .container{
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @media  (max-width: 900px){
        flex-wrap: wrap;
        justify-content: center;
      }
      .left{
        width: 50%;
        max-width: 435px;
        padding-left: 55px;
        padding-top: 40px;

        @media (max-width: 1100px){
          max-width: 480px;
        }
        @media (max-width: 900px){
          width: 100%;
          padding: 0;
          svg{
            display: none;
          }
        }
      }
    }

    h2{
      font-family: Montserrat, sans-serif;
      font-size: 52px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fafafa;
      text-transform: uppercase;
      strong{
        font-weight: bold;
      }

      @media (min-width: 1101px){
        min-height: 380px;
      }

      @media (max-width: 1000px){
        font-size: 48px;
      }

      @media (max-width: 900px){
        font-size: 28px;
        text-align: center;
        max-width: 90%;
        margin:0 auto 25px;
        display: block;
        strong{
          display: block;
        }
      }
    }
  }
  
  &__block-form{
    max-width: 545px;
    width: 50%;
    box-sizing: border-box;
    @media (max-width: 1170px) {
      padding-right: 25px;
      padding-left: 0;
    }
    @media (max-width: 900px) {
      padding: 0 25px;
      box-sizing: border-box;
      width: 100%;
    }
    p.mini{
      font-size: 11px;
      color: #fff;
    }

    h3{
      font-family: 'Montserrat',sans-serif;
      font-size: 50px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      max-width: 430px;
      width: 42%;
      margin: 30px 0 0 -80px;
      padding-top: 80px;
      padding-left: 80px;

      background-size: 77px;
      strong{
        font-weight: 800;
        display: block;
      }
      @media (max-width: 1220px) {
        padding-left: 25px;
        margin-left:0 ;
      }
      @media (max-width: 900px) {

        background-size: 43px;
        padding-top: 43px;
        padding-left: 0;
        margin-top: 20px;
        font-size: 28px;
        max-width: 95%;
        width: 100%;
        strong{
          display: inline;
        }
      }
    }
  }
  &__steps{
    width: 100%;
    position: relative;
    @media (max-width: 900px) {
      max-width: 100%;
      width: 100%;
      padding-bottom: 35px;
    }
    .MuiStepLabel-iconContainer{
      display: none;
    }
    .steps-top{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .steps-name{
      position: initial;
      width: 46px;
      padding: 0 15px;

      &>span, &>span.Mui-disabled,
      .MuiStepLabel-label{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 100%;
        border-radius: 50% !important;
        background-color: #ccc;
        font-family: Montserrat, sans-serif!important;
        font-size: 20px!important;
        font-weight: 600!important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #000!important;

        @media (max-width: 480px) {
          height: 35px;
          width: 35px;
          font-size: 15px !important;
        }

        span.Mui-active, span.Mui-completed{
          height: 46px;
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #e27484;
          font-family: Montserrat, sans-serif !important;
          font-size: 20px!important;
          font-weight: 600!important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #fff!important;

          @media (max-width: 480px) {
            height: 35px;
            width: 35px;
            font-size: 15px !important;
          }
        }
      }
    }
    div.Mui-disabled,.MuiStepConnector-horizontal{width: 74px; flex: initial}

    .MuiStepConnector-line{
      width: 74px;
      background-color: #ccc;
      height: 3px;
      border: none;
    }
    .btn.MuiButton-contained{
      width: 154px;
      box-sizing: border-box;
      background-image: linear-gradient(to left, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);

      @media (max-width: 900px){
        font-size: 14px;
        height: 37px;
        line-height: 25px;
      }
    }
  }
  &__contact{
    max-width: 546px;
    padding-left: 10px;
    padding-top: 45px;
    align-items: flex-start;
    h3{
      font-family: 'Montserrat',sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 6px;
      text-align: left;
      color: #000;
      text-transform: uppercase;
      margin: 0 0 45px;
      display: none;
    }
    .Mui-focused .MuiOutlinedInput-notchedOutline{
      border: 1px solid #0d0d0d !important;
    }
    .bottom{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: wrap;
      @media (max-width: 1120px){
        button{
          margin-top: 20px;
        }
      }

    }
    @media (max-width: 1120px){
      padding-right: 15px;
    }
    @media (max-width: 900px){
      padding-top: 30px;
      margin: 0 auto;
      max-width: 500px;
    }
  }
}

.congrats{
  height: 255px;
  width: 372px;
  border-style: solid;
  background-image: linear-gradient(to top, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
  border-radius: 10px;
  padding: 1px;
  margin-top: 151px;
  position: relative;

  @media (max-width: 900px){
    height: auto;
    width: 290px;
  }

  @media (max-width: 480px){
    width: 250px;
  }

  &__img{
    height: 66px;
    width: 66px;
    background: #0d0d0d url("../../img/icon/tick-pink.svg") no-repeat center center;
    background-size: 46px;
    position: absolute;
    top: -33px;
    left: 150px;
    @media (max-width: 900px){
      background-color: #000;
      height: 52px;
      width: 52px;
      background-size: 32px;
      left: calc(50% - 26px);
      top: -26px;
    }
  }

  &__inner{
    background: #0d0d0d;
    height: 295px;
    width: 372px;
    padding: 48px 45px;
    box-sizing: border-box;
    border-radius: 10px;

    @media (max-width: 900px){
      height: inherit;
      width: inherit;
      padding: 40px 45px;
      background-color: #000;
    }
  }
  &.contact{
    border: none;
    padding: 3px;
    margin-top: 0;
    position: relative;
    .congrats__img{
      background:  #fff url("../../img/icon/tick-pink.svg") no-repeat center center;
      position: initial;
      margin: 0 auto ;
      @media (max-width: 900px){
        background-color: #fff;
      }
    }
    .close{
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0;
      width: 25px;
      min-width: 25px;
      &:hover{
        background: none !important;
      }
    }
    .congrats__inner{
      background: #fff;
      padding:20px 0 0;
      height: 100%;

      p{
        margin: 0;
        height: 105px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }

  img{
    margin: 20px auto;
    display: block;

  }
  h3{
    width: 100%;
    font-family: Montserrat, sans-serif;
    font-size: 52px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fafafa;
    margin: 0;
    padding: 0;
    @media (max-width: 900px){
      font-size: 35px;
    }
  }
  p{
    font-family: Manrope, sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f996a2;
    @media (max-width: 900px){
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}

.steps_form_congrats {
  height: 295px;

  @media (max-width: 900px){
    height: auto;
    margin: 60px auto;

    .congrats__inner {
      padding: 40px 20px;
    }
  }
}
