.logos{
  &__box{
    padding: 45px 0 60px;
    background-color: #e9ecef;
    text-align: center;
    @media (max-width: 900px){
      padding: 40px 0;
    }
    h3{
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 4.4px;
      text-align: center;
      color: #000;
      text-transform: uppercase;
      margin: 0 10px 28px;

      @media (max-width: 900px){
        font-size: 15px;
      }
    }
    img{
      display: block;
      &.first{margin: 0 auto 50px;}

      @media (max-width: 900px){
        width: 175px;
      }
    }

    .secondBox {
      margin: 0 10px 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
    }

    .thirdHeader {
      @media (max-width: 900px) {
        max-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.33;
        letter-spacing: 3px;
      }
    }
  }
  &__list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1170px;
    margin: 0 auto;
    @media (max-width: 1190px){
      padding: 0 25px;
    }
    @media (max-width: 900px){
      justify-content: space-between;
      max-width: 282px;
      margin: 0 auto;
    }

    @media (max-width:350px){
      justify-content: space-around;
    }

    img{
      margin: 0 15px 20px;
      &:first-of-type{
        margin-left: 0;
      }
      &:last-of-type{
        margin-right: 0;
      }
      @media (max-width: 1100px){
        margin: 0 15px 15px;
      }
      @media (max-width: 900px){
        &:first-of-type{
          width: 102px;
          margin: 0 23px 25px;
        }
        &:nth-of-type(2){
          width: 62px;
          margin: 0 23px 25px;
        }
        &:nth-of-type(3){
          width: 105px;
          margin: 0 23px 25px;
        }
        &:nth-of-type(4){
          width: 65px;
          margin: 0 23px 25px;
        }
        &:nth-of-type(5){
          width: 80px;
          margin: 0 35px 25px;
        }
        &:nth-of-type(6){
          width: 118px;
          margin: 0 0 25px;
        }
        &:nth-of-type(7){
          width: 79px;
          margin: 0 auto 25px;

          @media (max-width:350px){
            margin: 0 40px 25px;
          }      
        }
        &:nth-of-type(8){
          width: 51px;
          margin: 0 30px 25px;
        }
      }
    }
  }
}