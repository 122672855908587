.pink{
  &__box{
    background-image: linear-gradient(to right, #f797a3, #fdcbc3);
    padding: 85px 0 70px;
    @media (max-width: 900px){
      padding: 35px 0;
    }
    h2{
      max-width: 575px;
      font-family: Manrope, sans-serif;
      font-size: 36px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      @media (max-width: 900px){
        font-size: 22px;
        padding: 0 25px;
      }
    }
    h3{
      font-family: Manrope, sans-serif;
      font-size: 21.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin: 30px 0 15px;
      @media (max-width: 900px){
        font-size: 14px;
      }
    }
    p{
      margin: 0;
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media (max-width: 900px){
        font-size: 14px;
        line-height: 1.14;
        padding:  0 25px;
      }
    }
  }
}