.situation{
  &__box{
    background: #e9ecef;
    padding: 80px 0 65px;
    @media (max-width: 900px){
      padding: 35px 0;
    }
    .container{
      margin: 0 auto;
    }
    h2{
      margin: 0 0 40px;
    }
    h3{
      margin: 50px 0 20px;
      font-family: Manrope, sans-serif;
      font-size: 31.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #e27484;
      text-transform: uppercase;
      @media (max-width: 900px){
        font-size: 18px;
        max-width: 205px;
        display: block;
        margin: 10px auto 20px;
      }
    }
    p{
      max-width: 1065px;
      display: block;
      margin: 0 auto;
      font-family: Manrope, sans-serif;
      font-size: 25.5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      @media (max-width: 1100px){
        margin: 0 25px;
      }
      @media (max-width: 900px){
        font-size: 14px;
      }
    }
  }
  &__grid{
    margin: 0;
    @media (max-width: 1200px){
      margin: 0 20px;
      width: calc(100% - 40px)!important;
    }
    @media (max-width: 1100px){
      margin: 0;
      width: 100% !important;
    }
  }
  &__item{
    min-height: 245px;
    width: calc(33.3% - 24px);
    margin: 0 12px;
    padding: 35px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 12.7px;
    box-shadow: 15.5px 26.8px 45px 0 rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    @media (max-width: 1200px){
      min-height: 275px;
    }
    @media (max-width: 1000px){
      min-height: 295px;
    }
    @media (max-width: 900px){
      width: 100%;
      margin: 0 0 20px;
      min-height: 200px;
    }

    @media (max-width: 480px){
      min-height: auto;
      max-height: 183px;
      height: 183px;
    }

    .proc{
      width: 100%;
      font-family: Montserrat, sans-serif;
      font-size: 87.5px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #f798a4;
      margin: 0 0 10px;
      span{
        font-size: 58px;
        font-weight: normal;
      }
      @media (max-width: 900px){
        font-size: 66.5px;
        width: 100%;
        span{
          font-size: 44.5px;
        }
      }
    }
    p{
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      margin: 0;
      @media (max-width: 900px){
        font-size: 13.5px;
        max-width: 250px;
      }
    }
    &:first-of-type{
      margin-left: 0;

      @media (max-width: 350px){
        padding: 35px 18px;
      }

      .proc{
        color: #e27484;
      }
    }
    
    &:last-of-type{
      margin-right: 0;
      .proc{
        color: #f9b7af;
      }
      p{
        max-width: 250px;
      }
    }
  }
}