.unicorn{
  &__box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 75px 0 60px;

    h2{
      margin: 0 0 50px;
      width: 100%;
      display: block;
      @media  (max-width: 900px){
        order:2;
        margin: 0 0 20px;
      }
    }
    @media (max-width: 1170px) {
      padding: 75px 25px 60px;
    }
    @media  (max-width: 900px){
      padding: 0 0 30px !important;
    }
    img{
      width: 50%;
      @media (max-width: 1100px) {
       width: 50%;
      }
      @media  (max-width: 900px){
        order: 1;
        width: 100%;
        margin-bottom: 40px;
      }

    }
  }
  &__text{
    max-width: 562px;
    width: 48%;

    @media  (max-width: 1070px){
      width: calc(50% - 17px);
    }
    @media  (max-width: 900px){
      order: 3;
      padding:  0 25px;
      width: 100%;
      max-width: 100%;
    }
    p{
      max-width: 562px;
      margin: 10px 0 20px;
      font-family: Manrope, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      a{
        color: #000;
        &:hover{
          text-decoration: none;
        }
      }
      @media (max-width: 1100px) {
        font-size: 15px;
      }
      @media (max-width: 1000px) {
        font-size: 14px;
      }
      @media  (max-width: 900px){
        font-size: 13.5px;
        max-width: 100%;
      }
    }
  }
}